import React, { useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Label, Input } from 'reactstrap';
import { IRootState } from '../../../../shared/reducers';
import { connect } from 'react-redux';
import { postPartnerPlacePayment } from '../../../../entities/partner-place/partner-place-redux';
import { TailSpin } from 'react-loader-spinner';
import { HttpRequestStatus } from '../../../../shared/model/enum/HttpRequestStatus';
import CurrencyInput from 'react-currency-input';
import { IPartnerPlacePaymentInfo } from '../../../../shared/model/partner-place.model';
import './companyChargeModal.scss';
import { deleteCompanyCharge, postCompanyCharge } from '../../../../entities/company/company-redux';
import { CompanyChargeAmountVM, ICompanyCancelCharge, ICompanyCreateChargeVM } from '../../../../shared/model/company';
import { BillingStatus, IBilling } from '../../../../shared/model/billing/billing';
import StringUtils from '../../../../util/StringUtils';
import { toast } from 'react-toastify';

export enum CompanyChargeModalType {
  CREATE = 'CREATE',
  EXCLUDE = 'EXCLUDE'
}

export interface ICompanyChargeModalInfo {
  open: boolean;
  type?: CompanyChargeModalType;
  companyChargeInfo?: {
    id?: number,
    name?: string,
    chargeAmount?: number
    billing?: IBilling,
    emails?: string[]
  };
}

interface ICompanyChargeModalProps extends StateProps, DispatchProps {
  companyChargeModalInfo?: ICompanyChargeModalInfo;
  setCompanyChargeModal: (companyChargeModal: ICompanyChargeModalInfo) => void;
}

const CompanyChargeModal = ({ companyChargeModalInfo, setCompanyChargeModal, postCompanyChargeStatus, postCompanyChargeProps, deleteCompanyChargeProps, deleteCompanyChargeStatus }: ICompanyChargeModalProps) => {
  const isCreating = companyChargeModalInfo?.type === CompanyChargeModalType.CREATE;

  const [shouldAppearCustomChargeAmountInput, setShouldAppearCustomChargeAmountInput] = React.useState(false);
  const [chargeAmount, setChargeAmount] = React.useState<number | undefined>(undefined);
  const [cancelReason, setCancelReason] = React.useState<string>('');
  const [emailSubject, setEmailSubject] = React.useState<string>(() => 'Fatura Keiken já disponível!');
  const [emailMessage, setEmailMessage] = React.useState<string>(() => 'Sua fatura referente ao último período já está disponível na plataforma Keiken. Abaixo, você encontrará um resumo dos principais detalhes:');
  const [emailList, setEmailList] = React.useState<string[]>(['']);

  React.useEffect(() => {
    if (companyChargeModalInfo?.open) {
      setEmailSubject('Fatura Keiken já disponível!');
      setEmailMessage('Sua fatura referente ao último período já está disponível na plataforma Keiken. Abaixo, você encontrará um resumo dos principais detalhes:');
    }
  }, [companyChargeModalInfo?.open]);

  React.useEffect(() => {
    const emails = companyChargeModalInfo?.companyChargeInfo?.emails || [];
    if (emails.length > 0) {
      setEmailList(companyChargeModalInfo?.companyChargeInfo?.emails!);
    }
  }, [companyChargeModalInfo?.companyChargeInfo?.emails]);

  const handleCustomChargeAmountCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShouldAppearCustomChargeAmountInput(event.target.checked);
  };

  const handleAddEmailInput = () => {
    setEmailList([...emailList, '']);
  };

  const handleRemoveEmailInput = (index: number) => {
    setEmailList(emailList.filter((_, i) => i !== index));
  };

  const handleEmailChange = (index: number, value: string) => {
    const updatedEmails = [...emailList];
    updatedEmails[index] = value;
    setEmailList(updatedEmails);
  };

  const handleSubmit = () => {
    if (isCreating) {
      handleCreate();
    } else {
      handleExclude();
    }
  };

  const handleCreate = () => {
    const objToSend: ICompanyCreateChargeVM = {
      company: {
        id: companyChargeModalInfo?.companyChargeInfo?.id!
      },
      chargeAmount: shouldAppearCustomChargeAmountInput ? chargeAmount! : companyChargeModalInfo?.companyChargeInfo?.chargeAmount!,
      emailInfo: {
        subject: emailSubject,
        message: emailMessage,
        recipients: emailList.filter(email => email.trim())
      }
    };

    postCompanyChargeProps(objToSend);
  };

  const handleExclude = () => {
    if (!cancelReason) {
      toast.error('Informe o motivo da exclusão');
      return;
    }

    const objToSend: ICompanyCancelCharge = {
      cancelReason,
      billingId: companyChargeModalInfo?.companyChargeInfo?.billing?.id!,
      companyId: companyChargeModalInfo?.companyChargeInfo?.id!
    };

    deleteCompanyChargeProps(objToSend);
  };

  const handleCloseModal = () => {
    setCompanyChargeModal({ open: false, companyChargeInfo: {} });
    setShouldAppearCustomChargeAmountInput(false);
    setChargeAmount(undefined);
    setEmailSubject('');
    setEmailMessage('');
    setEmailList(['']);
  };

  return (
    <div>
      <Modal
        isOpen={companyChargeModalInfo?.open}
        toggle={() => handleCloseModal()}
      >
        <ModalHeader>
          {isCreating ? 'Emitir boleto de cobrança' : 'Excluir Cobrança'}
        </ModalHeader>
        <ModalBody>
          {(postCompanyChargeStatus === HttpRequestStatus.ONGOING || deleteCompanyChargeStatus === HttpRequestStatus.ONGOING) ? (
            <span>
              <TailSpin height="30" width="30" color="black" />
            </span>
          ) : (
            <section className="invoice-info">
              <div>
                <div>
                  <label>Nome da Empresa:</label>
                  <span>{' ' + companyChargeModalInfo?.companyChargeInfo?.name}</span>
                </div>
              </div>

              {isCreating && (
                <>
                  <div className="checkbox-container">
                    <Label className="checkbox-label" check>
                      <Input
                        className="checkbox-input"
                        type="checkbox"
                        checked={shouldAppearCustomChargeAmountInput}
                        onChange={handleCustomChargeAmountCheckboxChange}
                      />{' '}
                      Cobrar outro valor
                    </Label>
                  </div>
                  {shouldAppearCustomChargeAmountInput && (
                    <FormGroup style={{ gap: '5px', display: 'flex', alignItems: 'center' }}>
                      <Label style={{ marginTop: '7px' }} for="customValue">Valor:</Label>
                      <CurrencyInput
                        autoComplete={'off'}
                        onChangeEvent={(e, masked, floatValue) => setChargeAmount(floatValue)}
                        value={chargeAmount}
                        decimalSeparator={','}
                        thousandSeparator={'.'}
                        prefix={'R$ '}
                      />
                    </FormGroup>
                  )}
                  <FormGroup>
                    <Label for="emailSubject">Assunto do Email</Label>
                    <Input
                      type="text"
                      id="emailSubject"
                      value={emailSubject}
                      onChange={e => setEmailSubject(e.target.value)}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label for="emailMessage">Mensagem do Email</Label>
                    <Input
                      type="textarea"
                      id="emailMessage"
                      value={emailMessage}
                      onChange={e => setEmailMessage(e.target.value)}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label>Lista de Emails</Label>
                    {emailList.map((email, index) => (
                      <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                        <Input
                          type="email"
                          value={email}
                          onChange={e => handleEmailChange(index, e.target.value)}
                          style={{ marginRight: '10px' }}
                        />
                        {index === emailList.length - 1 && (

                          <Button
                            color="danger"
                            onClick={() => handleRemoveEmailInput(index)}
                            disabled={emailList.length === 1}
                          >
                            -
                          </Button>
                        )}
                        {index === emailList.length - 1 && (
                          <Button
                            color="primary"
                            onClick={handleAddEmailInput}
                            style={{ marginLeft: '10px' }}
                          >
                            +
                          </Button>
                        )}
                      </div>
                    ))}
                  </FormGroup>
                </>
              )}

              {!isCreating && (
                <>
                  <div>
                    <p>Tem certeza que deseja excluir a cobrança?</p>
                  </div>
                  <div>
                    <p>Valor: {StringUtils.currencyPtBr(companyChargeModalInfo?.companyChargeInfo?.billing?.value)}</p>
                  </div>

                  <FormGroup>
                    <Label for="cancelReason">Motivo da exclusão</Label>
                    <Input
                      type="textarea"
                      name="text"
                      id="cancelReason"
                      maxLength={50}
                      onChange={e => setCancelReason(e.target.value)}
                    />
                  </FormGroup>
                </>
              )}
            </section>
          )}
        </ModalBody>

        <ModalFooter className="button-container">
          <Button
            color="primary"
            onClick={handleSubmit}
            disabled={!isCreating && companyChargeModalInfo?.companyChargeInfo?.billing?.status !== BillingStatus.WAITING_COMPANY_PAY}
          >
            {isCreating ? 'Criar Cobrança' : 'Excluir Cobrança'}
          </Button>
          <Button
            color="secondary"
            onClick={() => handleCloseModal()}
          >
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  postCompanyChargeStatus: storeState.company.postCompanyChargeStatus,
  deleteCompanyChargeStatus: storeState.company.deleteCompanyChargeStatus
});

const mapDispatchToProps = {
  postCompanyChargeProps: postCompanyCharge,
  deleteCompanyChargeProps: deleteCompanyCharge
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyChargeModal);
