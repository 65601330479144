import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Label, Input } from 'reactstrap';
import { connect } from 'react-redux';
import { IRootState } from '../../../../../shared/reducers';
import { manuallyPayCompanyBankSlipBilling } from '../../../../../entities/billing/billing.reducer';
import { toast } from 'react-toastify';
import './payManuallyModal.scss';
import StringUtils from '../../../../../util/StringUtils';
import { InterBankSlipStatusEnum } from '../../../../../shared/model/billing/billing';
import { HttpRequestStatus } from '../../../../../shared/model/enum/HttpRequestStatus';

export interface IPayManuallyModalInfo {
  open: boolean;
  paymentInfo?: {
    id: number;
    companyName: string;
    chargeAmount: number;
    interPaymentId: string;
  };
}

interface IPayManuallyModalProps extends StateProps, DispatchProps {
  payManuallyModalInfo: IPayManuallyModalInfo;
  setPayManuallyModal: (payManuallyModalInfo: IPayManuallyModalInfo) => void;
}

const PayManuallyModal = ({ payManuallyModalInfo, setPayManuallyModal, statusManuallyPayCompanyBankSlipBilling, manuallyPayCompanyBankSlipBillingProps }: IPayManuallyModalProps) => {

  const handleConfirmPayment = () => {

    const paymentDetails: [{ codigoSolicitacao: string, situacao: string }] = [{
      codigoSolicitacao: payManuallyModalInfo.paymentInfo?.interPaymentId!,
      situacao: InterBankSlipStatusEnum.RECEBIDO
    }];

    manuallyPayCompanyBankSlipBillingProps(paymentDetails);
  };

  const handleCloseModal = () => {
    setPayManuallyModal({ open: false, paymentInfo: undefined });
  };

  return (
    <Modal isOpen={payManuallyModalInfo.open} toggle={handleCloseModal}>
      <ModalHeader toggle={handleCloseModal}>Confirmação de Pagamento Manual</ModalHeader>
      <ModalBody>
        {statusManuallyPayCompanyBankSlipBilling === HttpRequestStatus.ONGOING ? (
          <div className="loading-spinner">
            <span>Aguarde...</span>
          </div>
        ) : (
          <div className="confirmation-details">
            <p>Tem certeza de que deseja confirmar manualmente o pagamento para a empresa abaixo?</p>
            <div>
              <strong>Empresa:</strong> {payManuallyModalInfo.paymentInfo?.companyName}
            </div>
            <div>
              <strong>Valor:</strong> {StringUtils.currencyPtBr(payManuallyModalInfo.paymentInfo?.chargeAmount)}
            </div>
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleConfirmPayment} disabled={statusManuallyPayCompanyBankSlipBilling === HttpRequestStatus.ONGOING}>
          Confirmar Pagamento
        </Button>
        <Button color="secondary" onClick={handleCloseModal}>
          Cancelar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  statusManuallyPayCompanyBankSlipBilling: storeState.billing.statusManuallyPayCompanyBankSlipBilling
});

const mapDispatchToProps = {
  manuallyPayCompanyBankSlipBillingProps: manuallyPayCompanyBankSlipBilling
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PayManuallyModal);
