import React from 'react';
import { connect } from 'react-redux';
import { IRootState } from '../../../shared/reducers';
import { HttpRequestStatus } from '../../../shared/model/enum/HttpRequestStatus';
import { TailSpin } from 'react-loader-spinner';
import { Table } from 'reactstrap';
import StringUtils from '../../../util/StringUtils';
import './companies-charges.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CompanyChargeAmountVM, ICompanyCharge } from '../../../shared/model/company';
import { getCompaniesCharges, getCompaniesChargesCreated } from '../../../entities/company/company-redux';
import CompanyChargeModal, { CompanyChargeModalType, ICompanyChargeModalInfo } from './components/companyChargeModal';
import { toast } from 'react-toastify';
import { BillingStatus, IBilling } from '../../../shared/model/billing/billing';
import { ICompanyEmail } from '../../../shared/model/company-email';
import PayManuallyModal, { IPayManuallyModalInfo } from './components/payManuallyModal/payManuallyModal';

interface ICompaniesChargeProps extends StateProps, DispatchProps { }

const CompaniesCharge = ({
  getCompaniesChargesProps,
  companiesCharges,
  companiesChargesStatus,
  postCompanyChargeStatus,
  getCompaniesChargesCreatedProps,
  companiesChargesCreated,
  deleteCompanyChargeStatus,
  statusManuallyPayCompanyBankSlipBilling
}: ICompaniesChargeProps) => {
  const [companyChargeModalInfo, setCompanyChargeModal] = React.useState<ICompanyChargeModalInfo>({
    open: false
  });
  const [payManuallyModalInfo, setPayManuallyModalInfo] = React.useState<IPayManuallyModalInfo>({
    open: false,
    paymentInfo: undefined
  });

  const [companiesChargesAndChargesCreated, setCompaniesChargesAndChargesCreated] = React.useState<ICompanyCharge[]>([]);

  const [monthYear, setMonthYear] = React.useState<Date>(new Date());
  const month = monthYear.getMonth() + 1;
  const year = monthYear.getFullYear();

  React.useEffect(() => {
    getCompaniesChargesProps(month, year);
    getCompaniesChargesCreatedProps(month, year);
  }, [month, year]);

  const postStatus = postCompanyChargeStatus;
  React.useEffect(() => {
    if (postStatus === HttpRequestStatus.SUCCESS) {
      toast.success('Cobrança emitida com sucesso!');

    } else if (postStatus === HttpRequestStatus.ERROR) {
      toast.error('Algo deu errado ao emitir cobrança!');
    }
    if (postStatus === HttpRequestStatus.SUCCESS || postStatus === HttpRequestStatus.ERROR) {
      setTimeout(() => {
        getCompaniesChargesProps(month, year);
        getCompaniesChargesCreatedProps(month, year);

        setCompanyChargeModal({
          open: false
        });
      }, 800);
    }

  }, [postStatus]);

  React.useEffect(() => {
    if (statusManuallyPayCompanyBankSlipBilling === HttpRequestStatus.SUCCESS) {
      toast.success('Pagamento confirmado manualmente com sucesso!');

    } else if (postStatus === HttpRequestStatus.ERROR) {
      toast.error('Não foi possível confirmar o pagamento manualmente!');
    }
    if (statusManuallyPayCompanyBankSlipBilling === HttpRequestStatus.SUCCESS || statusManuallyPayCompanyBankSlipBilling === HttpRequestStatus.ERROR) {
      setTimeout(() => {
        getCompaniesChargesProps(month, year);
        getCompaniesChargesCreatedProps(month, year);

        setPayManuallyModalInfo({ open: false, paymentInfo: undefined });

      }, 800);
    }

  }, [statusManuallyPayCompanyBankSlipBilling]);

  const deleteStatus = deleteCompanyChargeStatus;
  React.useEffect(() => {
    if (deleteStatus === HttpRequestStatus.SUCCESS) {
      toast.success('Cobrança excluída com sucesso!');

    } else if (deleteStatus === HttpRequestStatus.ERROR) {
      toast.error('Algo deu errado ao excluir cobrança!');
    }
    if (deleteStatus === HttpRequestStatus.SUCCESS || deleteStatus === HttpRequestStatus.ERROR) {
      setTimeout(() => {
        getCompaniesChargesProps(month, year);
        getCompaniesChargesCreatedProps(month, year);

        setCompanyChargeModal({
          open: false
        });
      }, 800);
    }

  }, [deleteStatus]);

  React.useEffect(() => {
    if (companiesCharges && companiesChargesCreated) {
      const companiesChargesAndChargesCreatedMap: ICompanyCharge[] = companiesCharges.map(
        (companyCharge: CompanyChargeAmountVM) => {
          const companyChargeCreated: ICompanyCharge | undefined = companiesChargesCreated.find(
            (companyChargeCreatedItem: ICompanyCharge) => companyChargeCreatedItem.company.id === companyCharge.company.id
          );

          return {
            company: {
              id: companyCharge.company.id,
              name: companyCharge.company.name,
              chargeAmount: companyCharge.chargeAmount,
              billing: companyChargeCreated?.company?.billing,
              emails: companyCharge?.company?.emails?.map((emailObject: ICompanyEmail) => emailObject?.email)
            }
          };
        }
      );

      setCompaniesChargesAndChargesCreated(companiesChargesAndChargesCreatedMap);
    }
  }, [companiesCharges, companiesChargesCreated]);

  const handleSelectCompany = (id: number, name: string, chargeAmount: number, emails: string[]) => {
    setCompanyChargeModal({
      open: true,
      type: CompanyChargeModalType.CREATE,
      companyChargeInfo: {
        id,
        name,
        chargeAmount,
        emails
      }
    });
  };

  const handleOpenModalExcludeCharge = (id: number, name: string, billing: IBilling) => {
    setCompanyChargeModal({
      open: true,
      type: CompanyChargeModalType.EXCLUDE,
      companyChargeInfo: {
        id,
        name,
        billing,
        emails: []
      }
    });
  };

  const handleOpenModalPayManuallyCharge = (
    id: number,
    companyName: string,
    chargeAmount: number,
    interPaymentId: string
  ) => {
    setPayManuallyModalInfo({
      open: true,
      paymentInfo: { id, companyName, chargeAmount, interPaymentId }
    });
  };

  const handleOnChangeMonth = (action: 'NEXT' | 'BACK') => {
    const newMonthYear = new Date(monthYear);
    if (action === 'NEXT') {
      newMonthYear.setMonth(newMonthYear.getMonth() + 1);
    } else {
      newMonthYear.setMonth(newMonthYear.getMonth() - 1);
    }

    setMonthYear(newMonthYear);
  };

  const getChargeStatusByCompany = (companyId: number): string => {
    if (!companiesChargesCreated) return 'Carregando...';

    const companyCharge: ICompanyCharge = companiesChargesCreated.find(
      (companyChargeCreated: ICompanyCharge) => companyChargeCreated.company.id === companyId
    );

    const status = companyCharge?.company?.billing?.status;

    if (!status) return 'Não emitida';

    return status === BillingStatus.WAITING_COMPANY_PAY
      ? 'Aguardando Pagamento'
      : status === BillingStatus.PAID
        ? 'Pago'
        : 'Não emitida';
  };

  const monthWritten = monthYear.toLocaleString('pt-BR', { month: 'long' });
  const monthWIthFirstLetterUppercase = monthWritten.charAt(0).toUpperCase() + monthWritten.slice(1);
  const yearWritten = monthYear.getFullYear();

  return (
    <>
      <div className="genereal--info--and--date--time--container">

        <div className="partners--payment__date__info">
          <div className="arrow--item__left" onClick={() => handleOnChangeMonth('BACK')}>
            <FontAwesomeIcon color="black" icon={'arrow-left'} />
          </div>

          <h3 className="month--year--to--search">{monthWIthFirstLetterUppercase + ' - ' + yearWritten}</h3>

          <div className="arrow--item__right" onClick={() => handleOnChangeMonth('NEXT')}>
            <FontAwesomeIcon color="black" icon={'arrow-right'} />
          </div>
        </div>
      </div>
      {companiesChargesStatus === HttpRequestStatus.ONGOING && (
        <div className="loading-container">
          <TailSpin height="50" width="50" color="green" />
        </div>
      )}

      <Table className="partners--payment__table">
        <thead>
          <tr>
            <th>{'Empresa'}</th>
            <th>{'Valor Cobrança'}</th>
            <th>{'Emitir Cobrança'}</th>
            <th>{'Status da cobrança'}</th>
            <th>{'Excluir Cobrança'}</th>
            <th>
              {'Baixa Sistema'}
              <FontAwesomeIcon
                title="Caso a empresa tenha pago a cobrança por outro meio, clique aqui para dar baixa no sistema"
                style={{ marginLeft: '5px' }}
                icon={'exclamation-circle'}
                color="black"
              />
            </th>
          </tr>
        </thead>

        <tbody>
          {companiesChargesAndChargesCreated?.length > 0 && companiesChargesAndChargesCreated?.map((companyCharge: ICompanyCharge, index) => {
            return (
              <tr
                key={index}
                style={{ backgroundColor: 'white', cursor: 'default' }}
              >
                <td style={{ textAlign: 'initial' }}>{companyCharge?.company?.name}</td>
                <td style={{ textAlign: 'center' }}>{StringUtils.currencyPtBr(companyCharge?.company?.billing?.value ?? companyCharge?.company?.chargeAmount)}</td>
                <td>
                  <button
                    style={{ minWidth: '110px' }}
                    className={'btn btn-primary'}
                    disabled={getChargeStatusByCompany(companyCharge?.company?.id) != 'Não emitida'}
                    onClick={() => handleSelectCompany(
                      companyCharge?.company?.id,
                      companyCharge?.company?.name!,
                      companyCharge?.company?.chargeAmount!,
                      companyCharge?.company?.emails!
                    )}
                  >
                    {'Emitir Cobrança'}
                  </button>
                </td>
                <td style={{ textAlign: 'center' }}>
                  <span style={{
                    display: 'inline-block',
                    color: 'white',
                    backgroundColor: getChargeStatusByCompany(companyCharge?.company?.id) === 'Aguardando Pagamento' ? 'orange' :
                      getChargeStatusByCompany(companyCharge?.company?.id) === 'Pago' ? 'green' : 'red',
                    borderRadius: '5px',
                    padding: '5px',
                    minWidth: '200px'
                  }}>
                    {companiesChargesCreated
                      ? getChargeStatusByCompany(companyCharge?.company?.id)
                      : 'Carregando...'}
                  </span>
                </td>

                <td>
                  <button
                    style={{ minWidth: '110px' }}
                    className={'btn btn-danger'}
                    disabled={getChargeStatusByCompany(companyCharge?.company?.id) != 'Aguardando Pagamento'} // se é diferente de aguardando então ja pagou, se ja pagou nao tem como cancelar a cobrança
                    onClick={() => handleOpenModalExcludeCharge(
                      companyCharge?.company?.id,
                      companyCharge?.company?.name!,
                      companyCharge?.company?.billing
                    )}
                  >
                    {'Excluir Cobrança'}
                  </button>
                </td>
                <td>
                  <button
                    style={{ minWidth: '110px' }}
                    className="btn"
                    disabled={getChargeStatusByCompany(companyCharge.company.id) !== 'Aguardando Pagamento'}
                    onClick={() =>
                      handleOpenModalPayManuallyCharge(
                        companyCharge?.company?.id,
                        companyCharge?.company?.name!,
                        companyCharge?.company?.chargeAmount!,
                        companyCharge?.company?.billing?.interPaymentId!
                      )
                    }
                  >
                    {'Dar Baixa'}
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>

      </Table >

      <CompanyChargeModal
        companyChargeModalInfo={companyChargeModalInfo}
        setCompanyChargeModal={setCompanyChargeModal}
      />

      <PayManuallyModal
        payManuallyModalInfo={payManuallyModalInfo}
        setPayManuallyModal={setPayManuallyModalInfo}
      />

    </>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  companiesCharges: storeState.company.companiesCharges,
  companiesChargesStatus: storeState.company.companiesChargesStatus,
  postCompanyChargeStatus: storeState.company.postCompanyChargeStatus,
  companiesChargesCreated: storeState.company.companiesChargesCreated,
  deleteCompanyChargeStatus: storeState.company.deleteCompanyChargeStatus,
  statusManuallyPayCompanyBankSlipBilling: storeState.billing.statusManuallyPayCompanyBankSlipBilling
});

const mapDispatchToProps = {
  getCompaniesChargesProps: getCompaniesCharges,
  getCompaniesChargesCreatedProps: getCompaniesChargesCreated
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompaniesCharge);
