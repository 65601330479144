import { IDiscountFee, ISystemFee } from './system-fee.model';
import { IPriceTable } from './price-table';
import { ICompanyWallet } from './company-wallet';
import { IImage } from './image';
import { IActivityCategory } from './activity-category';
import { IBilling } from './billing/billing';
import { ICompanyEmail } from './company-email';

export enum ICompanyStatus {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED'
}
export interface ICompany {
  id?: number;
  name?: string;
  cnpj?: string;
  keikenPayEnabled?: boolean;
  discountValue?: number;
  companyDependent?: ICompany;
  discountFee?: IDiscountFee;
  keikenPayActivityCategories?: ICompanyKeikenPayActivityCategory[];
  priceTable?: IPriceTable;
  status?: ICompanyStatus;
  wallet?: ICompanyWallet;
  image?: IImage;
  promptAi?: string;
}

export interface ICompanyKeikenPayActivityCategory {
  id?: number;
  activityCategory?: IActivityCategory;
  enabled?: boolean;
  keikenPayFee?: ISystemFee;
}

export interface ICompanyReport {
  id: number;
  url: string;
  reportDate: string;
  status?: 'ENABLE' | 'DISABLE';
  company?: ICompany;
}

export interface CompanyChargeAmountVM {
  company: {
    id: number;
    name?: string;
    emails?: ICompanyEmail[];
  };
  chargeAmount: number;
}

export interface ICompanyCharge {
  company: {
    id: number;
    name?: string;
    chargeAmount?: number;
    billing: IBilling;
    emails?: string[];
  };
}

export interface ICompanyCreateChargeVM {
  company: {
    id: number;
  };
  chargeAmount: number;
  emailInfo: {
    subject: string;
    message: string;
    recipients: string[];
  };
}

export interface ICompanyCancelCharge {
  companyId: number;
  billingId: number;
  cancelReason: string;
}
